"use client";

import { AIIcon } from "@/components/icons/icons";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button, buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { cn } from "@/lib/utils";
import { trpc } from "@/providers/TrpcProvider";
import { useUser } from "@clerk/nextjs";
import { LockIcon, Menu, Triangle, UserPlus2Icon } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { useState } from "react";
import { useCurrentPrice } from "./CurrentPriceProvider";
import { CopilotSearchBar } from "./CopilotSearchBar";
export default function Navbar({
  isSignedIn
}: {
  isSignedIn: boolean;
}) {
  const {
    isLoaded,
    user
  } = useUser();
  const [open, setOpen] = useState(false);
  const {
    data: ticker
  } = trpc.tickers.getTickerAssignment.useQuery(undefined, {
    enabled: !!isLoaded && !!isSignedIn
  });
  const {
    currentPrice,
    lastMarketDayClosePrice: lastMarketDayOpenPrice
  } = useCurrentPrice();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  let localTicker = ticker?.ticker;
  if (!isSignedIn) {
    if (pathname.includes("/stock/")) {
      localTicker = pathname.split("/stock/")[1];
    } else {
      const tickerParam = searchParams.get("ticker");
      localTicker = tickerParam || "AMZN";
    }
  }
  localTicker = localTicker?.toUpperCase();
  const showCopilotNavbar = searchParams.get("copilot") === "preview-copilot";
  return <nav className={cn("bg-background border-b border-border fixed top-0 left-0 right-0 z-[100]", !showCopilotNavbar && "hidden")} data-sentry-component="Navbar" data-sentry-source-file="CopilotNavigation.tsx">
      <div className="max-w-screen-2xl mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center gap-4">
            {!pathname.includes("/onboarding") ? <Link href={`/?ticker=${localTicker}`} className="flex items-center gap-1">
                <Image src="/ontrade-logo.svg" alt="OnTrade Logo" width={108} height={28} />
              </Link> : <div className="flex items-center gap-1">
                <Image src="/ontrade-logo.svg" alt="OnTrade Logo" width={108} height={28} />
              </div>}
            {!pathname.includes("/onboarding") && <div className="hidden gap-2">
                <Link href={`/`} className={cn(pathname === "/" ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                  Home
                </Link>
                <Link href={`/scenario?ticker=${localTicker}`} className={cn(pathname === "/scenario" ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                  Scenarios
                </Link>
                <Link href={`/stock/${localTicker}`} className={cn(pathname.includes("/stock") ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                  Company Stock
                </Link>
                {isSignedIn ? <Link href="/strategy" className={cn(pathname === "/strategy" ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                    Strategy
                  </Link> : <span className={cn("flex flex-row items-center gap-1 opacity-50 cursor-not-allowed pointer-events-none", buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                    <LockIcon className="w-4 h-4" />
                    Strategy
                  </span>}
                {isSignedIn ? <Link href="/settings/grants" className={cn(pathname === "/settings/grants" ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                    Grants & Vestings
                  </Link> : <span className={cn("flex flex-row items-center gap-1 opacity-50 cursor-not-allowed pointer-events-none", buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                    <LockIcon className="w-4 h-4" />
                    Grants & Vestings
                  </span>}
                {isSignedIn && <Link href="/feedback" className={cn(pathname === "/feedback" ? buttonVariants({
              size: "sm",
              variant: "default"
            }) : buttonVariants({
              size: "sm",
              variant: "ghost"
            }))} onClick={() => setOpen(false)}>
                    Feedback
                  </Link>}
              </div>}
          </div>
          <CopilotSearchBar data-sentry-element="CopilotSearchBar" data-sentry-source-file="CopilotNavigation.tsx" />
          {!pathname.includes("/onboarding") && <div className="flex gap-2 items-center lg:gap-4">
              {!isSignedIn && <Link href={`/sign-up`} className={buttonVariants({
            variant: "default",
            size: "sm"
          })}>
                  Join the Beta
                </Link>}

              {isSignedIn && <Link href="/invites" className={cn(pathname === "/invites" ? buttonVariants({
            variant: "default",
            size: "sm"
          }) : buttonVariants({
            variant: "ghost",
            size: "sm"
          }), "gap-1")} onClick={() => setOpen(false)}>
                  <UserPlus2Icon className="w-5 h-5 text-primary" />
                  <span className="font-semibold text-xs text-primary">
                    Refer a friend
                  </span>
                </Link>}

              {isSignedIn && ticker && <Link href={`/stock/${ticker.ticker}`} className={cn(pathname === "/stock" ? "" : "")} onClick={() => setOpen(false)}>
                  <div className="flex flex-col items-center justify-centers">
                    <div className="flex items-center justify-center text-xs bg-primary rounded-sm px-2 text-primary-foreground font-bold">
                      {ticker.ticker}
                    </div>
                    <div className={cn("text-xs flex flex-row gap-0.5 items-center font-medium", currentPrice - (lastMarketDayOpenPrice || 0) > 0 ? "text-green-600" : "text-red-600")}>
                      <Triangle className={cn("w-2 h-2", currentPrice - (lastMarketDayOpenPrice || 0) > 0 ? "fill-green-600" : "fill-red-600 rotate-180")} />
                      {currentPrice?.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD"
                })}
                    </div>
                  </div>
                </Link>}

              {isSignedIn && <Link href="/settings" className={cn("hidden lg:block", pathname === "/settings" ? "border-2 border-primary rounded-full" : "border-2 border-transparent")} onClick={() => setOpen(false)}>
                  <Avatar className="w-8 h-8">
                    <AvatarImage src={user?.imageUrl} alt="User avatar" />
                    <AvatarFallback>
                      {user?.fullName?.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                </Link>}

              <div className="">
                <Sheet open={open} onOpenChange={setOpen}>
                  <SheetTrigger asChild>
                    <Button variant="ghost" size="icon">
                      <Menu className="h-6 w-6" />
                      <span className="sr-only">Open menu</span>
                    </Button>
                  </SheetTrigger>
                  <SheetContent side="right">
                    <div className="flex flex-col gap-4 mt-10 text-right">
                      <Link href={`/`} className={cn(pathname === "/" ? "" : "")} onClick={() => setOpen(false)}>
                        <p className="text-lg font-medium">Home</p>
                        <p className="text-xs text-muted-foreground">
                          Your dashboard for all things RSU
                        </p>
                      </Link>
                      <Link href={`/scenario?ticker=${localTicker}`} className={cn(pathname === "/scenario" ? "" : "")} onClick={() => setOpen(false)}>
                        <div className="flex flex-row justify-end">
                          <p className="text-lg font-medium">
                            Scenario Explorer
                          </p>
                          <AIIcon className="w-4 h-4" />
                        </div>
                        <p className="text-xs text-muted-foreground">
                          What your RSUs could be worth
                        </p>
                      </Link>
                      <Link href="/strategy" className={cn(isSignedIn ? "" : "opacity-50 cursor-not-allowed pointer-events-none")} onClick={() => setOpen(false)}>
                        <div className="flex flex-row justify-end">
                          <div className="flex flex-row items-center gap-1">
                            {!isSignedIn && <LockIcon className="w-4 h-4" />}
                            <p className="text-lg font-medium">
                              Strategy Builder
                            </p>
                          </div>
                          <AIIcon className="w-4 h-4" />
                        </div>
                        <p className="text-xs text-muted-foreground">
                          When and what RSUs to sell, and why
                        </p>
                      </Link>
                      <Link href="/settings/grants" className={cn(isSignedIn ? "" : "opacity-50 cursor-not-allowed pointer-events-none")} onClick={() => setOpen(false)}>
                        <div className="flex flex-row justify-end items-center gap-1">
                          {!isSignedIn && <LockIcon className="w-4 h-4" />}
                          <p className="text-lg font-medium">
                            Your Grants & Vestings
                          </p>
                        </div>
                      </Link>
                      <Separator />
                      <Link href={`/stock/${localTicker}`} className={cn(pathname === "/stock" ? "" : "")} onClick={() => setOpen(false)}>
                        <p className="text-lg font-medium">
                          {localTicker || "Company"} Stock
                        </p>
                      </Link>
                      <Link href="/invites" className={cn(isSignedIn ? "" : "opacity-50 cursor-not-allowed pointer-events-none")} onClick={() => setOpen(false)}>
                        <p className="text-lg font-medium">Refer a Friend</p>
                        <p className="text-xs text-muted-foreground">
                          Invite {localTicker || "your company"} coworkers or
                          tech industry peers to use OnTrade for their RSUs
                        </p>
                      </Link>
                      <Separator />
                      {isSignedIn && <Link href="/feedback" className={cn(pathname === "/feedback" ? "" : "")} onClick={() => setOpen(false)}>
                          <p className="text-lg font-medium">
                            Give Beta Feedback
                          </p>
                        </Link>}
                      {isSignedIn && <Link href="/settings" className={cn(pathname === "/settings" ? "" : "")} onClick={() => setOpen(false)}>
                          <p className="text-lg font-medium">Your Account</p>
                        </Link>}
                      {!isSignedIn && <>
                          <Link href="/sign-up" className={cn(pathname === "/sign-up" ? "" : "")} onClick={() => setOpen(false)}>
                            <p className="text-lg font-medium">Join the Beta</p>
                          </Link>
                          <Link href="/sign-in" className={cn(pathname === "/sign-in" ? "" : "")} onClick={() => setOpen(false)}>
                            <p className="text-lg font-medium">Sign In</p>
                          </Link>
                        </>}
                    </div>
                  </SheetContent>
                </Sheet>
              </div>
            </div>}
        </div>
      </div>
    </nav>;
}