import Image from "next/image";
import { DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Dialog } from "@/components/ui/dialog";
import FullChat from "../chat/_components/CopilotChat";
import { useSearchParams } from "next/navigation";
export const CopilotSearchBar = () => {
  const searchParams = useSearchParams();
  const conversationId = searchParams.get("conversationId") ?? undefined;
  const showCopilotNavbar = searchParams.get("copilot") === "preview-copilot";
  return <Dialog defaultOpen={!!conversationId} open={!showCopilotNavbar ? false : undefined} data-sentry-element="Dialog" data-sentry-component="CopilotSearchBar" data-sentry-source-file="CopilotSearchBar.tsx">
      <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="CopilotSearchBar.tsx">
        <div className="flex flex-row items-center gap-2 bg-secondary/10 rounded-lg p-3 shadow-[inset_0_2px_4px_rgba(0,0,0,0.1)] grow mx-2 cursor-pointer">
          <div className="text-sm font-bold text-secondary flex flex-row items-center">
            AI Copilot
            <Image src="/blue-star-bold.svg" alt="Copilot Icon" width={14} height={14} className="mt-[-10px]" data-sentry-element="Image" data-sentry-source-file="CopilotSearchBar.tsx" />
          </div>

          <div className="flex flex-col items-top min-w-32 cursor-pointer">
            <span className="text-sm text-[rgba(0,52,112,0.35)] ml-5">
              Ask a question
            </span>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl h-[80vh] max-h-[80vh] p-4" data-sentry-element="DialogContent" data-sentry-source-file="CopilotSearchBar.tsx">
        <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CopilotSearchBar.tsx">
          <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CopilotSearchBar.tsx">AI Copilot</DialogTitle>
        </DialogHeader>
        <FullChat conversationId={conversationId} data-sentry-element="FullChat" data-sentry-source-file="CopilotSearchBar.tsx" />
      </DialogContent>
    </Dialog>;
};